/** @jsx jsx  */
import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import { css, jsx } from "@emotion/core"
import Confetti from "react-dom-confetti"
import queryString from "query-string"

import Layout from "../components/layout/Layout"
import ChatIcon from "../images/ChatIcon"
import TapIcon from "../images/TapIcon"
import { Col, Row } from "boostly-ui2"
import RedeemButtonIcon from "../images/RedeemButtonIcon"

const WelcomeText = styled.h1`
  font-family: "righteous";
  font-weight: 200;
  font-size: 48px;
  text-align: center;
  letter-spacing: 2px;
  max-width: 90%;
  color: #8147ff;
  margin-top: 10px;
  padding: 0px;
`
const Title1 = styled.h2`
  font-family: proxima-nova, sans-serif;
  font-weight: bold;
  font-size: 24px;
  letter-spacing: 0px;
  color: #343450;
  margin: 0px;
  padding: 0px;
`
const Title2 = styled.h3`
  font-family: proxima-nova, sans-serif;
  font-weight: 450;
  font-size: 18px;
  letter-spacing: 0.5px;
  color: #17174e;
  margin: 8px 0px 0px 0px;
  padding: 0px;
`
const config = {
  angle: 90,
  spread: 70,
  startVelocity: 60,
  elementCount: 50,
  dragFriction: 0.1,
  duration: 3000,
  stagger: 0,
  width: "10px",
  height: "10px",
  colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
}

const AlreadySubbed = () => {
  return (
    <Col
      x
      y
      space={"end"}
      css={css`
        min-height: 100%;
      `}
    >
      <span
        css={css`
          font-size: 70px;
        `}
      >
        🤔
      </span>
      <WelcomeText
        css={css`
          font-size: 24px;
        `}
      >
        You've already been subscribed. <br />
        <br /> Thanks for checking in!
      </WelcomeText>
    </Col>
  )
}

const NewSub = () => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    setTimeout(() => setShow(true), 500)
  }, [])

  return (
    <>
      <Col x space={"end"}>
        <span
          css={css`
            font-size: 70px;
          `}
        >
          🎉
        </span>
        <WelcomeText>Welcome to the club!</WelcomeText>
      </Col>
      <Col x>
        <Row space={`between`}>
          <ChatIcon
            css={css`
              height: 50px;
              width: 75px;
            `}
          />
          <Col ml={`24px`}>
            <Title1>Check your phone for a text from us.</Title1>
            <Title2>A link to your coupon will be there.</Title2>
          </Col>
        </Row>
        <Row mt={`36px`}>
          <TapIcon
            css={css`
              height: 75px;
              width: 75px;
            `}
          />
          <Col ml={`24px`} space={`between`}>
            <Title1>
              Tap{" "}
              <RedeemButtonIcon
                css={css`
                  transform: translateY(12px);
                `}
              />{" "}
              when you're ready.
            </Title1>
            <Title2>Show the screen to the staff before the timer ends!</Title2>
          </Col>
        </Row>
        <Confetti
          active={show}
          config={config}
          css={css`
            transform: translateY(50px);
          `}
        />
      </Col>
    </>
  )
}

const Confirm = () => {
  const [subAlreadyExists, setSubAlreadyExists] = useState(false)
  useEffect(() => {
    const { result } = queryString.parse(window.location.search)
    if (result && result === "ALREADY_EXISTED") {
      setSubAlreadyExists(true)
    }
  }, [])
  return (
    <Layout bgShape={require(`../images/MuralWave.svg`)}>
      {subAlreadyExists ? <AlreadySubbed /> : <NewSub />}
    </Layout>
  )
}

export default Confirm

import React from 'react'

const RedeemButtonIcon = ({className}) => (
	<svg className={className} width="127px" height="38px" viewBox="0 0 127 38" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
			<defs>
					<path d="M17,0 L106,0 C113.179702,-1.31888981e-15 119,5.82029825 119,13 C119,20.1797017 113.179702,26 106,26 L17,26 C9.82029825,26 4,20.1797017 4,13 C4,5.82029825 9.82029825,1.31888981e-15 17,0 Z" id="path-1"></path>
					<filter x="-8.7%" y="-30.8%" width="117.4%" height="176.9%" filterUnits="objectBoundingBox" id="filter-2">
							<feMorphology radius="0.5" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"></feMorphology>
							<feOffset dx="0" dy="2" in="shadowSpreadOuter1" result="shadowOffsetOuter1"></feOffset>
							<feGaussianBlur stdDeviation="2.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
							<feColorMatrix values="0 0 0 0 0.917647059   0 0 0 0 0.325490196   0 0 0 0 0.364705882  0 0 0 0.25 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
					</filter>
			</defs>
			<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
					<g id="Confirmation" transform="translate(-142.000000, -479.000000)">
							<g id="Tap-Instructions" transform="translate(32.000000, 480.000000)">
									<g id="Main-Button-Copy" transform="translate(112.000000, 3.000000)">
											<g id="Button">
													<g id="Rectangle-7-Copy-5">
															<use fill="black" fillOpacity="1" filter="url(#filter-2)" xlinkHref="#path-1"></use>
															<use fill="#EA535D" fillRule="evenodd" xlinkHref="#path-1"></use>
													</g>
													<text id="Get-coupon" fontFamily="Righteous-Regular, Righteous" fontSize="19" fontWeight="normal" letterSpacing="1" fill="#F6FCFF">
															<tspan x="24.4707031" y="20">Redeem</tspan>
													</text>
											</g>
									</g>
							</g>
					</g>
			</g>
	</svg>
)

export default RedeemButtonIcon

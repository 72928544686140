import React from 'react'

const ChatIcon = ({className}) => (
	<svg className={className} width="38px" height="38px" viewBox="0 0 38 38" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
		<g id="Page-1" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
			<g id="Confirmation" transform="translate(-43.000000, -350.000000)" fill="#000000" fillRule="nonzero">
			<g id="Wait-Instructions" transform="translate(43.000000, 341.000000)">
				<g id="chat-comment-oval-speech-bubble-with-text-lines" transform="translate(19.000000, 28.000000) scale(-1, 1) translate(-19.000000, -28.000000) translate(0.000000, 9.000000)">
				<path d="M24.9375,20.1875 L13.0625,20.1875 C12.407,20.1875 11.875,20.7195 11.875,21.375 C11.875,22.0305 12.407,22.5625 13.0625,22.5625 L24.9375,22.5625 C25.593,22.5625 26.125,22.0305 26.125,21.375 C26.125,20.7195 25.593,20.1875 24.9375,20.1875 Z M27.3125,13.0625 L10.6875,13.0625 C10.032,13.0625 9.5,13.5945 9.5,14.25 C9.5,14.9055 10.032,15.4375 10.6875,15.4375 L27.3125,15.4375 C27.968,15.4375 28.5,14.9055 28.5,14.25 C28.5,13.5945 27.968,13.0625 27.3125,13.0625 Z M19,0 C8.50728105,0 0,7.44321895 0,16.625 C0,21.8726013 2.78467974,26.5441797 7.125,29.5913203 L7.125,38 L15.4481797,32.9495392 C16.6001013,33.1396013 17.7851797,33.25 19,33.25 C29.4939608,33.25 38,25.806781 38,16.625 C38,7.44321895 29.4939608,0 19,0 Z M19,30.875 C17.613,30.875 16.2735,30.7135 14.986219,30.4391797 L9.39543791,33.7997582 L9.47025817,28.2921797 C5.18464052,25.7141405 2.375,21.4534216 2.375,16.625 C2.375,8.75546078 9.81821895,2.375 19,2.375 C28.181781,2.375 35.625,8.75546078 35.625,16.625 C35.625,24.4945392 28.181781,30.875 19,30.875 Z" id="Shape" />
				</g>
			</g>
			</g>
		</g>
	</svg>
)

export default ChatIcon